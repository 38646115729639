import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

// Components
import Layout from '../../components/layout/Layout';
import PaginationBlock from '../../components/block/Pagination';
import RealisationCard from '../../components/card/Realisation';

// Partner
const Partner = ({ pageContext, data }) => {

  const {t} = useTranslation();

  const { languagePath } = pageContext;

  const pageImage = { 
    url: data.partner.frontmatter.image.logo ? data.partner.frontmatter.image.logo.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.partner.frontmatter.image.logo ? data.partner.frontmatter.image.logo.childImageSharp.fluid.srcWebp : null,
    type: data.partner.frontmatter.image.logo ? data.partner.frontmatter.image.logo.type : null,
    width: data.partner.frontmatter.image.logo ? data.partner.frontmatter.image.logo.childImageSharp.gatsbyImageData.width : null,
    height: data.partner.frontmatter.image.logo ? data.partner.frontmatter.image.logo.childImageSharp.gatsbyImageData.height : null,
    alt: data.partner.frontmatter.image.alt ? data.partner.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.partners'),
          item: languagePath + "partners/"
        },
        { 
          name: data.partner.frontmatter.name
        },
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic"
      pageTitle={data.partner.frontmatter.meta.title} 
      pageDescription={data.partner.frontmatter.meta.description} 
      pageKeywords={data.partner.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-2">
        <nav className="border-bottom" aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/partners/`}>{t('breadcrumb.partners')}</Link></li>
            <li className="breadcrumb-item active">{data.partner.frontmatter.name}</li>
          </ol>
        </nav>
        {data.partner &&
          <div className="w-lg-75 border-bottom content-space-1 mx-lg-auto">
            <div className="row">
              <div className="col-sm mb-2 mb-sm-0">
                <div className="d-sm-flex">
                  <div className="flex-shrink-0 mb-3 mb-sm-0">
                    <GatsbyImage
                      image={getImage(data.partner.frontmatter.image.logo)}
                      className="avatar avatar-xl avatar-circle border"
                      alt={data.partner.frontmatter.image.alt}
                    />                  
                  </div>
                  <div className="flex-grow-1 ms-sm-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h1 className="h3 mb-0">
                        {data.partner.frontmatter.name &&
                          <Link to={`/partner/${data.partner.frontmatter.path}/`} className="text-dark">{data.partner.frontmatter.name}</Link>
                        }
                      </h1>
                    </div>
                    {data.partner.frontmatter.country &&
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <ul className="list-inline text-center mb-0">
                          {data.partner.frontmatter.country && 
                            <li key="country" className="list-inline-item">
                              {(() => {
                                if(data.partner.frontmatter.country === 'de'){
                                  return (
                                    <div className="btn btn-outline-primary btn-xs rounded">
                                      <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/de-de.png`} alt={t('countries.' + data.partner.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.partner.frontmatter.country + '.label')}
                                    </div>
                                  )
                                }
                                if(data.partner.frontmatter.country === 'fr'){
                                  return (
                                    <div className="btn btn-outline-primary btn-xs rounded">
                                      <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/fr-fr.png`} alt={t('countries.' + data.partner.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.partner.frontmatter.country + '.label')}
                                    </div>
                                  )
                                }
                                if(data.partner.frontmatter.country === 'gb'){
                                  return (
                                    <div className="btn btn-outline-primary btn-xs rounded">
                                      <StaticImage className="avatar avatar-xss avatar-rouned me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/en-gb.png`} alt={t('countries.' + data.partner.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.partner.frontmatter.country + '.label')}
                                    </div>
                                  )
                                }
                                if(data.partner.frontmatter.country === 'ru'){
                                  return (
                                    <div className="btn btn-outline-primary btn-xs rounded">
                                      <StaticImage className="avatar avatar-xss avatar-rounded me-2" imgClassName="avatar-img" objectFit="none" quality="30" src={`../../images/flags/locales/ru-ru.png`} alt={t('countries.' + data.partner.frontmatter.country + '.label')} width={20} height={20} />{t('countries.' + data.partner.frontmatter.country + '.label')}
                                    </div>
                                  )
                                }
                              })()}
                            </li>
                          }
                          {data.partner.frontmatter.website_url && 
                            <li key="website" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs rounded" target="_blank" rel="noopener noreferrer" href={data.partner.frontmatter.website_url}>
                                <i className="bi-globe"></i> {t('website')}
                              </a>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    {data.partner.frontmatter.social_networks &&
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <ul className="list-inline text-center mb-0">
                          {data.partner.frontmatter.social_networks.linkedIn && 
                            <li key="linkedIn" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.partner.frontmatter.social_networks.linkedIn}>
                                <i className="bi-linkedin"></i>
                              </a>
                            </li>
                          }
                          {data.partner.frontmatter.social_networks.facebook && 
                            <li key="facebook" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.partner.frontmatter.social_networks.facebook}>
                                <i className="bi-facebook"></i>
                              </a>
                            </li>
                          }
                          {data.partner.frontmatter.social_networks.instagram && 
                            <li key="instagram" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.partner.frontmatter.social_networks.instagram}>
                                <i className="bi-instagram"></i>
                              </a>
                            </li>
                          }
                          {data.partner.frontmatter.social_networks.twitter && 
                            <li key="twitter" className="list-inline-item">
                              <a className="btn btn-outline-primary btn-xs btn-icon rounded" target="_blank" rel="noopener noreferrer" href={data.partner.frontmatter.social_networks.twitter}>
                                <i className="bi-twitter"></i>
                              </a>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                    {data.partner.body &&
                      <div>
                        <MDXRenderer>
                          {data.partner.body}
                        </MDXRenderer>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="w-lg-75 content-space-1 mx-lg-auto">
          <div className="row gx-3 mb-7">
            {data.realisations.nodes &&
              data.realisations.nodes.map(realisation => (
                <RealisationCard key={realisation.id} data={realisation} cardStyle="1" cardClassName="col-sm-6 col-lg-6 mb-4"/>
              ))
            }
          </div>              
          <div>
            <PaginationBlock 
              path={`partner/${data.partner.frontmatter.path}`}
              currentPage={pageContext.currentPage} 
              numPages={pageContext.numPages} 
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

Partner.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    partner: PropTypes.shape({
      frontmatter: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export default Partner

export const query = graphql`
  query ($partner: String!, $language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        socialNetworks {
          linkedIn
          facebook
          messenger
          instagram
          twitter
        }
      }
    }
    partner: mdx(
        fileAbsolutePath: { regex: "/data/partners/"}
        frontmatter: { path: { eq: $partner }, active: { eq: true }, language: {eq: $language} }
      ) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        name
        description
        country
        website_url
        social_networks {
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          logo {
            childImageSharp {
              gatsbyImageData(width: 400)
              fluid(quality: 50, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          cover {
            childImageSharp {
              gatsbyImageData(width: 1600)
            }
          }
          card {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
          }
          alt
        }
        home
        active
        language
        path
        date(formatString: "D MMMM YYYY")
      }
      body
    }
    realisations: allMdx(
      filter: { 
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { partners: { in: [$partner] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "partner", "countries", "realisations", "categories", "tags", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`